*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body { font-family: $font-family-sans-serif; font-weight: 400; margin: 0; background-color: $white; display: flex; flex-direction: column; min-height: 100vh;}
p{
	margin: 0;
	font-size: 19px;
    line-height: 28px;
    color: #444444;
    font-family: "Kefa-Regular", sans-serif;
    font-weight: 400;
}
.container-fluid {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.container {
    max-width: 1350px;
    width: 100%;
    margin: auto;
    padding: 0 15px;
}
a{
	color: #0A0C08;
	text-decoration: none;
	transition: 0.3s all ease-in-out;
}
a:hover{
	color: $light-green;
	transition: 0.3s all ease-in-out;
	text-decoration: none;
}
a:focus-visible{
	outline: none;
}
h1{
	margin: 0;
	font-size: $px38;
	line-height: 50px;
}
h2{
	font-size: $px36;
	line-height: 52px;
	margin: 0;
}
h3{
	margin: 0;
	font-size: $px34;
}
h4{
	margin: 0;
	font-size: $px24;
	line-height: 1.25;
}
h5{
	font-size: $px18;
}
h6{
	font-size: $px16;
}
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }
.header{
	background-color: $white;
	padding: 36px 0px;
	position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
	transition: all 0.5s;
	
.header_main{
	.logo{
		max-width: 	610px;
		
	a{
		display: block;
		img{
			width: 100%;
		}
	}
	}
	
.navbar{
	ul{
		display: flex;
		margin: 0;
		padding: 0;
	li{
		font-weight: 700;
		font-size: $px16;
		line-height: 24px;
		color: #444444;
		display: $display-inline;
		font-family: 'Kefa-Bold', sans-serif;
		margin-right: 30px;
		text-transform: uppercase;
		position: relative;
	a{
		color: #444444;
		position: relative;
		padding-bottom: 5px;
		
	&:hover{
		color: #655dc6;
	}
	}
	
	&:last-child{ margin-right: 0px; }
	}
	}
}
}
}
.header .header_main .navbar ul li a:before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 2px;
    bottom: 0;
    background: #655dc6;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.header .header_main .navbar ul li a:hover:before {
    width: 100%;
    opacity: 1;
    visibility: visible;
}
.header.sticky{
	-webkit-animation: smoothScroll .5s forwards;
    -moz-animation: smoothScroll 1s forwards;
    -ms-animation: smoothScroll 1s forwards;
    -o-animation: smoothScroll 1s forwards;
    animation: smoothScroll 1s forwards;
}
@-webkit-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-moz-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-o-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@-ms-keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }
@keyframes smoothScroll { 0% { transform: translateY(-40px); } 100% { transform: translateY(0px); } }

.nav-icon { 
	display: none; 
	-webkit-transform: rotate(0deg); 
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg); 
	transform: rotate(0deg); 
	-webkit-transition: .5s ease-in-out; 
	-moz-transition: .5s ease-in-out; 
	-o-transition: .5s ease-in-out; 
	transition: .5s ease-in-out; 
	cursor: pointer; 
	z-index: 9; 
	width: 35px; 
	height: 24px; 
	position: absolute; 
	top: 33px;
    right: 33px; 
	border-radius: 0; 
}
.nav-icon span{
	display: block; 
	position: absolute; 
	height: 3px; 
	background: #444444; 
	opacity: 1; 
	left: 6px; 
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg); 
	-o-transform: rotate(0deg); 
	transform: rotate(0deg);  
	-webkit-transition: .25s ease-in-out; 
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out; 
	border-radius: 20px; 
 }
.nav-icon span:nth-child(1) { 
	top: 0px; 
	width: 35px; 
	left: auto; 
}
.nav-icon span:nth-child(2)	{ 
	top: 10px; 
	width: 35px;  
	left: auto;  
}
.nav-icon span:nth-child(3)	{ 
	top: 20px; 
	width: 35px;  
	left: auto;  
}
.nav-icon.open span:nth-child(1){ 
	top: 10px; 
	right: 0px; 
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg); 
	-o-transform: rotate(135deg); 
	transform: rotate(135deg);
}
.nav-icon.open span:nth-child(2){ 
	opacity: 0; 
}
.nav-icon.open span:nth-child(3){ 
	top: 10px;
	-webkit-transform: rotate(-135deg); 
	-moz-transform: rotate(-135deg); 
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);  
	right: 0px; 
}
.header .header_main .main_menu nav ul.sub-menu{ 
    background-color: $white;
    padding: 15px 15px;
    position: absolute;
    width: 190px;
    left: 0;
    top: 100%;
    z-index: 99;
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.header .header_main .main_menu nav ul.sub-menu li{ margin-right: 0; padding-bottom: 10px; }
.header .header_main .main_menu nav ul.sub-menu li:last-child { padding-bottom: 0px; }
.header .header_main .main_menu nav ul.sub-menu li a{  text-transform: capitalize;     letter-spacing: 1.5px;}
.header .header_main .main_menu nav ul.sub-menu li a:hover {     color: #655dc6; }
.header .header_main .main_menu nav ul li.current-page-ancestor ul.sub-menu li a:hover {     color: #655dc6; }
.click_menu {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 3px;
    cursor: pointer;
    top: 5px;
    border-style: solid;
    transform: rotate(45deg);
    border-width: 0px 2px 2px 0;
    padding: 3px;
    background-position: right;
    z-index: 999;
    border-color: #444444;
    display: none;
}
.current-menu-item > a{
	color: #655dc6 !important;
}
.header .header_main .navbar ul li.current-menu-item > a:before{
    width: 100%;
    opacity: 1;
    visibility: visible;
}
main{
	flex: 1;
	margin-top: 143px;
}
/****banner_img****/
.banner_slider{
	position: relative;
}
.banner_slider:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    height: 560px;
    background: url(../images/green-bg.png) no-repeat;
    z-index: 9;
    background-size: cover;
    background-position: bottom right;
	z-index: -1;
}
.banner_img{
	position: relative;
	clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
}
.banner_img:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../images/banner-overlay.png) no-repeat;
    z-index: 9;
    background-size: cover;
}
.banner_img img{
	width: 100%;
    height: calc(100vh - 122px);
    -o-object-position: center;
    object-position: center;
    -o-object-fit: cover;
    object-fit: cover;
}
.banner_txt{
	padding: 93px 0px;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}
.banner_maintxt{
	max-width: 495px;
    position: relative;
    z-index: 9;
}
.banner_title h6{
	font-weight: 700;
	font-family: 'Kefa-Bold', sans-serif;
	font-size: 29px;
	letter-spacing: 5px;
	color: #93d500;
	line-height: 49px;
	text-transform: uppercase;
}
.banner_title em{
	font-weight: 700;
	font-family: 'Charter-BoldItalic', sans-serif;
	font-size: 29px;
	color: #ffffff;
	letter-spacing: 5px;
	display: block;
	position: relative;
	max-width: 600px;
	margin: 0 auto;
}
.banner_title em:before{
	content:'';
	position: absolute;
	left: 0;
	top: 23px;
	width: 37%;
	height: 3px;
	background-color: #fcd700;
}
.banner_title em:after{
	content:'';
	position: absolute;
	right: 0;
	top: 23px;
	width: 37%;
	height: 3px;
	background-color: #fcd700;
}
.banner_title h3{
	font-weight: 400;
	font-family: 'Phosphate-Inline', sans-serif;
	font-size: 98px;
	line-height: 98px;
	color: #ffffff;
	text-transform: uppercase;
	margin-bottom: 5px;
	letter-spacing: 7px;
}
.banner_title h5{
	font-weight: 700;
	font-family: 'Kefa-Bold', sans-serif;
	font-size: 41px;
	line-height: 59px;
	color: #ffd600;
	text-transform: uppercase;
	position: relative;
	display: inline-block;
	margin-bottom: 30px;
}
.banner_title h5:before{
	content: "";
    position: absolute;
    left: -30px;
    top: 20px;
    width: 20px;
    height: 20px;
    background: url(../images/star.svg) no-repeat;
    background-size: cover;
}
.banner_title h5:after{
	content: "";
    position: absolute;
    right: -30px;
    top: 20px;
    width: 20px;
    height: 20px;
    background: url(../images/star.svg) no-repeat;
    background-size: cover;
}
.banner_box{
	background: url('../images/banner_bg.png') no-repeat;
	background-size: cover;
	padding: 40px 38px 51px;
	max-width: 370px;
    margin: 0 auto;
	background-size: 100%;
	background-position: center; 
}
.banner_box p{
	padding: 28px 0px;
	border-top: 3px solid #ffffff;
	border-bottom: 3px solid #ffffff;
	margin-bottom: 18px;
}
.banner_box p:last-child{
	border: 0;
	padding: 0;
	margin-bottom: 0;
}
.banner_box img{
	width: 65px;
	margin: 0 auto;
	height: auto;
}
/****our_mission****/
.our_mission{
	padding-bottom: 45px;
}
.our_mission_main{
    max-width: 795px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 9;
}
.our_mission_title {
    width: 197px;
    height: 197px;
    margin: 0 auto;
    margin-bottom: 26px;
    background: url(../images/yellow-bg.png) no-repeat;
    background-size: cover;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -114px;
}
.our_mission_title h2{
	font-size: 48px;
	letter-spacing: 1px;
	line-height: 53px;
	text-transform: uppercase;
	color: #444444;
	font-family: 'BebasNeue', sans-serif;
	text-align: center;
}
.our_mission_txt p{
	margin-bottom: 30px;
}
.our_mission_txt p span	{
	color: #655dc6;
}
/****partner_sec****/
.partner_sec{
	padding: 105px 0px;
	border-top: 3px solid #00bed6;
}
.partner_title{
	max-width: 915px;
	margin: 0 auto;
	margin-bottom: 35px;
}
.partner_title h6{
	font-weight: 700;
	font-family: 'Kefa-Bold', sans-serif;
	font-size: 27px;
	letter-spacing: 5px;
	color: #655dc6;
	line-height: 59px;
	text-transform: uppercase;
}
.partner_title em{
	font-weight: 700;
	font-family: 'Charter-BlackItalic', sans-serif;
	font-size: 29px;
	color: #00bed6;
	letter-spacing: 5px;
	display: block;
	position: relative;
	max-width: 600px;
	margin: 0 auto;
}
.partner_title em:before{
	content:'';
	position: absolute;
	left: 0;
	top: 15px;
	width: 37%;
	height: 3px;
	background-color: #fcd700;
}
.partner_title em:after{
	content:'';
	position: absolute;
	right: 0;
	top: 15px;
	width: 37%;
	height: 3px;
	background-color: #fcd700;
}

.partner_title h3{
	font-weight: 400;
	font-family: 'Phosphate-Inline', sans-serif;
	font-size: 98px;
	color: #655dc6;
	text-transform: uppercase;
	margin-bottom: 55px;
	letter-spacing: 5px;
}
.partner_title p{
	font-weight: 400;
	font-family: 'RobotoSlab-Regular', sans-serif;
}
.partner_slider {
	margin-bottom: 60px;
}
.partner_slider img{
	width: 100%;
}	
.btn.btn-purple{
	font-size: 18px;
    letter-spacing: 1px;
    line-height: 28px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-family: "Kefa-Bold", sans-serif;
    border-radius: 20px;
    background-color: #635fa9;
    padding: 6px 22px;
	border: 0;
}
.btn.btn-purple:hover{
	background-color: #00bed6;
}
/********/
.make_diffsec {
	padding-bottom: 101px;
    max-width: 1130px;
    margin: 0 auto;
}
.make_difftxt{
	width: calc(100% - 412px);
    padding-right: 147px;
}
.make_difftxt h6{
	font-size: 34px;
	letter-spacing: 7px;
	line-height: 49px;
	text-transform: uppercase;
	color: #93d500;
	font-family: 'Kefa-Bold', sans-serif;
	font-weight: 700;
	padding-top: 95px;
}
.make_difftxt h3{
	font-size: 98px;
	letter-spacing: 7px;
	line-height: 97px;
	font-family: 'Phosphate-Inline', sans-serif;
	color: #655dc6;
	font-style: normal;
	text-transform: uppercase;
	margin-bottom: 30px;
}
.make_difftxt p{
	margin-bottom: 25px;
}
.make_difftxt ul{
	max-width: 480px;
	margin: 0 auto;
	margin-top: 50px;
}
.make_difftxt ul li{
	display: inline-block;
	margin-right: 38px;
	
&:last-child{
	margin-right: 0;
}
}
.make_difftxt ul li img{ 
	display: block;
	max-width: 110px;
	margin: 0 auto;
	margin-bottom: 13px;
}
.make_difftxt ul li a{
	display: block;
	font-size: 20px;
	letter-spacing: 2px;
	line-height: 53px;
	text-transform: uppercase;
	color: #444444;
	font-family: 'Kefa-Bold', sans-serif;
	font-weight: 700;	
}
.make_diffimg{
	max-width: 412px;
}
.make_diffimg img{
	width: 100%;
	margin-bottom: 57px;
}

/****testimonial_sec****/
.testimonial_sec{
	padding: 93px 0px;
}
.testimonial_slider{
	max-width: 950px;
	margin: 0 auto;
	text-align: center;
	
em{
	font-size: 97px;
	line-height: 97px;
	font-family: 'Phosphate-Inline', sans-serif;
	color: #00bed6;
	font-style: normal;
	margin-bottom: 47px;
}
p{
	font-family: 'Charter-Italic', sans-serif;
	font-size: 39px;
	line-height: 49px;
	color: #444444;
	margin-bottom: 35px;
}
span{
	display: block;
	font-weight: 700;
	font-family: 'Kefa-Bold', sans-serif;
	font-size: 20px;
	color: #655dc6;
	text-transform: uppercase;
	margin-bottom: 45px;
}
}
.partner_slider.slick-initialized .slick-slide{
	margin: 0px 20px;
}
/****stories_sec****/
.stories_sec{
	padding: 75px 0px;
	background-color: #00bed6;
	overflow: hidden;
	position: relative;
}
.stories_sec:before{
	content:'';
	position: absolute;
	right: -60px;
    bottom: -130px;
	width: 848px;
	height: 475px;
	background: url('../images/img4.png') no-repeat;
	background-size: cover;
}
.stories_title{
	margin-bottom: 75px;
	
h3{
	font-size: 98px;
	letter-spacing: 12px;
	line-height: 94px;
	text-transform: uppercase;
	color: #ffffff;
	font-family: 'Phosphate-Inline', sans-serif;
	font-style: normal;
	display: inline-block;
	position: relative;
}
}
.stories_title h3:before{
	content:'';
	position: absolute;
	left: -110px;
    top: 25px;
	width: 80px;
	height: 39px;
	background: url('../images/hand-img.png') no-repeat;
	background-size: cover;
}
.stories_title h3:after{
	content:'';
	position: absolute;
	right: -110px;
    top: 25px;
	width: 80px;
	height: 39px;
	background: url('../images/hand-img.png') no-repeat;
	background-size: cover;
}
.stories_main{
	max-width: 700px;
	margin: 0 auto;
	margin-bottom: 78px;
}
.stories_box{
	background-color: #ffffff;
	padding: 16px 16px;	
	margin-right: 68px;
	width: 45.14%;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
}
.stories_box:last-child{
	margin-right: 0px;
}
.stories_box:last-child .stories_img img{
	margin-top: -16px;
}
.stories_img{
	margin-bottom: 16px;
	position: relative;
}
.stories_img img{
	width: 100%;
}
.stories_img span{
	width: 77px;
	position: absolute;
	right: 15px;
    top: -17px;
	
img{
	width: 100%;
}
}

.stories_txt {
	padding: 0px 15px;
	text-align: center;
}
.stories_txt span{
	font-size: 48px;
	letter-spacing: 1px;
	line-height: 53px;
	text-transform: uppercase;
	color: #444444;
	font-family: 'BebasNeue', sans-serif;
	border-bottom: 5px solid #635fa9;
	text-align: center;
	display: inline-block;
	vertical-align: top;
}
.stories_txt em{
	font-size: 23px;
    font-family: "Phosphate-Inline", sans-serif;
    color: #444444;
    font-style: normal;
    text-align: center;
    background: url(../images/img6.png) no-repeat;
    background-size: 54px 110px;
    background-position: 0 16px;
    padding: 35px 13px 55px;
    margin: 0px 15px;
    position: relative;
    top: 8px;
}
.stories_txt h4{
	font-size: 48px;
	letter-spacing: 1px;
	line-height: 53px;
	text-transform: uppercase;
	color: #444444;
	font-family: 'BebasNeue', sans-serif;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	 max-height: 60px;
}
.stories_box:last-child .stories_txt h4{
	border-bottom: 5px solid #635fa9;
}
.stories_btm {
	position: relative;
}
.stories_btm p{
	margin-bottom: 30px;
}
.stories_btm a.btn.btn-purple{
	background: #f9d506;
	color: #444444;
	padding: 6px 33px;
}
.stories_btm a.btn.btn-purple:hover{
	background: #655dc6;
	color: #ffffff;
}
/****news_sec****/
.news_sec{
	padding: 110px 0px 90px;
	background-color: #645dc7;
}
.news_sec:before{
	content:'';
	position: absolute;
	left: -185px;
    top: 55px;
	width: 652px;
	height: 457px;
	background: url('../images/img2.png') no-repeat;
	background-size: cover;
}
.news_title{
	margin-bottom: 75px;
	
h3{
	font-size: 98px;
	letter-spacing: 12px;
	line-height: 94px;
	text-transform: uppercase;
	color: #ffffff;
	font-family: 'Phosphate-Inline', sans-serif;
	font-style: normal;
	display: inline-block;
	position: relative;
}
}
.news_title h3:before{
	content:'';
	position: absolute;
	left: -110px;
    top: 25px;
	width: 80px;
	height: 39px;
	background: url('../images/hand-img.png') no-repeat;
	background-size: cover;
}
.news_title h3:after{
	content:'';
	position: absolute;
	right: -110px;
    top: 25px;
	width: 80px;
	height: 39px;
	background: url('../images/hand-img.png') no-repeat;
	background-size: cover;
}
.news_main {
	max-width: 1065px;
	margin: 0 auto;
}
.news_box{
	width: 45.68%;
	margin-right: 92px;
	border-top: 30px solid #f9d506;	
	padding: 38px 38px;
	background: #ffffff;
	position: relative;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
}
.news_box:nth-child(2){
	margin-right: 0;
}
.news_txt {
	padding-left: 20px;
    padding-right: 80px;
    padding-bottom: 40px;
    margin-bottom: 25px;
    border-bottom: 3px solid #f9d506;
	min-height: 172px;
}
.news_txt h4{
	font-size: 33px;
	line-height: 43px;
	color: #444444;
	font-family: 'Kefa-Regular', sans-serif;
	font-weight: 400;
}
.news_btn{
	width: 100%;
	margin-top: 101px;
}
.news_btn a.btn.btn-purple{
	background: #f9d506;
	color: #444444;
	padding: 6px 33px;
	
&:hover{
    background: #00bed6;
	color: #ffffff;
}
}
.news_txt span{
    display: block;
    position: absolute;
    right: 38px;
    top: -30px;
    width: 53px;
    height: 125px;
	background: url('../images/new-bg.png') no-repeat;
	background-size: cover;
}
.news_box a.btn.btn-purple{
    padding: 6px 46px;
}
.news_box:nth-child(2) .news_txt span{
	background: url('../images/new-bg1.png') no-repeat;
	background-size: cover;
}
.news_txt span a{
    transform: rotate(90deg);
    display: block;
	font-size: 18px;
	letter-spacing: 1px;
	line-height: 53px;
	text-transform: uppercase;
	color: #444444;
	font-weight: 700;
	font-family: 'Kefa-Bold', sans-serif;
	position: relative;
    top: 18px;
}
/****footer****/
.footer{
	background-color: $white;
	padding: 28px 0px 45px;

.footer_top{
	margin-bottom: 40px;
	justify-content: space-between;
	position: relative;
}
.footer_btm{
	p{
		font-size: $px14;
		color: rgba(69, 69, 69, 0.5);
		font-family: 'RobotoSlab-Regular', sans-serif;
		
	span{
		margin: 0px 10px;
	}
	}
}
}
.footer_list ul{
	justify-content: flex-end;
	margin: 0;
	padding: 0;
}

.footer_list ul li {
	margin-right: 20px;
	display: block;
	
&:last-child{
	margin-right: 0px;
}
}
.footer_list ul li a {
	display: flex;
}
.footer_list ul li a i {
	font-size: 42px;
    color: #40cee0;
}
.footer_list ul li a:hover i {
    color: #635ea9;
    transition: 0.3s all ease-in-out;
}
.footer_form{
	display: flex;
	align-items: center;
	width: 783px;
}
.footer_form h3{
	font-family: 'Phosphate-Inline', sans-serif;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 38px;
	color: #00bed6;
	margin-right: 15px;
	letter-spacing: 5px;
}
.emailbox{
	display: flex;
	align-items: center;
	max-width: 450px;
	margin: 0;
}
.emailbox input {
    border: 1px solid rgba(69, 69, 69, 0.4);
    width: 278px;
    color: rgba(69, 69, 69, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin-right: 20px;
    padding: 3px 15px 4px;
	border-radius: 9px;
	background-color: #ffffff;
	font-family: 'RobotoSlab-Regular', sans-serif;
}
.emailbox input:focus-visible {
    outline: none;
}
.emailbox input[type=submit] {
    background: #635ea9 ;
    border: 0;
    font-size: 18px;
	line-height: 28px;
    outline: 0;
    -webkit-appearance: none;
    border-radius: 50px;
	padding: 6px 12px;
	color: $white;
	width: 152px;
	letter-spacing: 1px;
	text-transform: uppercase;
	display: inline-block;
    font-weight: 700;
	font-family: 'Kefa-Bold', sans-serif;
	margin-right: 0px;
}
.emailbox input[type=submit]:hover {
	background:	#00bed6;
	transition: 0.3s all ease-in-out;
}
.emailbox input::placeholder {  
	color: rgba(69, 69, 69, 0.5);
}
.emailbox input::-webkit-input-placeholder {  
	color: rgba(69, 69, 69, 0.5);
}
.emailbox input::-moz-placeholder {  
	color: rgba(69, 69, 69, 0.5); 
}
.emailbox input:-ms-input-placeholder {  
	color: rgba(69, 69, 69, 0.5);
}
.emailbox input:-moz-placeholder{  
	color: rgba(69, 69, 69, 0.5);
}
/****banner_img****/
.inner_banner{
	position: relative;
}
.inner_banner:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 70px;
    width: 100%;
    height: 423px;
    background: url(../images/green-bg1.png) no-repeat;
    z-index: 9;
    background-size: cover;
    background-position: bottom right;
	z-index: -1;
}
.inner_bannerimg{
	position: relative;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 60%);
}
.inner_bannerimg:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../images/innbanner-overlay.png) no-repeat;
    z-index: 9;
    background-size: cover;
}
.inner_bannerimg img{
	width: 100%;
    height: 693px;
	object-fit: cover;
	    object-position: center;
}
.inner_bannertxt{
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.inner_bannertxt h1{
	font-size: 98px;
	letter-spacing: 12px;
	line-height: 94px;
	text-transform: uppercase;
	color: #ffffff;
	font-weight: 400;
	font-family: 'Phosphate-Inline', sans-serif;
}
.years_sec{
	margin-bottom: 65px;
}
.years_txt{
	max-width: 870px;
	margin: 0 auto;
	margin-bottom: 60px;
}
.years_txt p{
	margin-bottom: 29px;
	
&:last-child{
	margin-bottom: 0;
}
}
.bryson_img{
	max-width: 316px;
	background-color: #ffffff;
	padding: 20px 20px;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
	
img{
	width: 100%;
}
}
.bryson_sec{
	padding-bottom: 97px;
}
.bryson_txt{
	width: calc(100% - 385px);
	padding-left: 60px;
}
.bryson_txt h3{
	font-size: 60px;
	letter-spacing: 8px;
	line-height: 94px;
	text-transform: uppercase;
	color: #655dc6;
	font-weight: 400;
	font-family: 'Phosphate-Inline', sans-serif;
	margin-bottom: 5px;
}
.inndiffrence_sec{
	background-color: #635ea9;
	position: relative;
}
.inndiffrence_sec:before{
	content: '';
	position: absolute;
	right: 0;
	bottom: -60px;
	width: 845px;
	height: 487px;
	background: url('../images/hand-img2.png') no-repeat;
}
.inndiffrence_img{
	max-width: 460px;
	
img{
	width: 100%;
	max-width: 460px; 	
	height: 550px;
	object-fit: contain;
	object-position: bottom;
}
}
.inndiffrence_txt{
	padding: 69px 0px;
	width: calc(100% - 525px);
	text-align: center;
}
.inndiffrence_txt h6{
	font-size: 34px;
    letter-spacing: 7px;
    line-height: 49px;
    text-transform: uppercase;
    color: #93d500;
    font-family: "Kefa-Bold", sans-serif;
    font-weight: 700;
}
.inndiffrence_txt h3 {
    font-size: 98px;
    letter-spacing: 7px;
    line-height: 97px;
    font-family: "Phosphate-Inline", sans-serif;
    color: #ffffff;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.inndiffrence_txt p {
    font-size: 19px;
    line-height: 27px;
    font-family: "Kefa-Regular", sans-serif;
    margin-bottom: 25px;
    color: #ffffff;
}
.inndiffrence_txt a.btn.btn-purple {
    background: #f9d506;
    color: #444444;
	
&:hover{
    background: #00bed6;
	color: #ffffff;
}
}
.gallery_sec{
	padding: 100px 0px 129px;
	background-color: #00bed6;
}
.gallery_box{
	background-color: #ffffff;
	padding: 15px 15px;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
	margin-bottom: 60px;
	
	img{
		width: 100%;
	}
}
.gal-col{
	padding: 0px 30px;
}
.bryson_main {
	max-width: 1130px;
	margin: 0 auto;
}
.inndiffrence_sec .container, .gallery_sec .container{
	max-width: 1130px;
}
.years_slider , .slider-nav{
	max-width: 1130px;
	margin: 0 auto;
	padding: 0px 30px;
	position: relative;
	margin-bottom: 25px;
}
.years_slider:before, .slider-nav:before{
	content: "";
    position: absolute;
    left: 0;
    top: 24px;
    width: 100%;
    height: 1px;
    background-color: rgba(100, 96, 169, 0.5);
}
.years_slider .slick-prev, .slider-nav .slick-prev{ left: -29px; z-index: 9;  width: 29px; height: 29px; top: 24px; }
.years_slider .slick-prev:before, .slider-nav .slick-prev:before { content: "";  position: absolute;  width: 29px; height: 29px;  background-image: url('../images/arrow-left.svg'); top: auto; bottom: 0px; z-index: 999; left: 0; background-size: 100%; opacity: 1; background-repeat: no-repeat; }
.years_slider .slick-next, .slider-nav .slick-next { right: -29px; z-index: 9; width: 29px;  height: 29px; top: 24px; }
.years_slider .slick-next:before, .slider-nav .slick-next:before { content: "";  position: absolute; width: 29px;  height: 29px; background-image: url('../images/arrow-right.svg'); top: auto; bottom: 0px; z-index: 999; right: 0; background-size: 100%; opacity: 1; background-repeat: no-repeat; }
.year_box{
	text-align: center;
	position: relative;
	display: block;
	cursor: pointer;
}
.year_box > a{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
}
.year_img{
	width: 75px;
	margin: 0 auto;
	margin-bottom: 12px;
	display: block;
img{
	width: 100%;
}
}
.year_txt{
	font-family: 'BebasNeue', sans-serif;
	font-size: 27px;
	color: #444444;
	font-weight: 400;
	
	h5{
		font-family: 'BebasNeue', sans-serif;
		font-size: 27px;
		color: #444444;
	}
	a{
		color: #444444;
	}
}
.inntestimonial_sec{
	padding: 65px 0px;
	background: #ffffff;
}
.calgallery_sec{
	background-color: #00bed6;
	padding-bottom: 70px;
}
.calgallery_title {
    display: flex;
    align-items: center;
    justify-content: center;
	margin-bottom: 65px;
}
.calgallery_title span {
    font-size: 91px;
    letter-spacing: 1px;
    line-height: 100px;
    text-transform: uppercase;
    color: #444444;
    font-family: "BebasNeue", sans-serif;
    border-bottom: 5px solid #635fa9;
    text-align: center;
}
.calgallery_title em, .calgallery_title span:nth-child(2) {
    letter-spacing: 0;
    font-size: 43px;
    font-family: "Phosphate-Inline", sans-serif;
    color: #444444;
    font-style: normal;
    text-align: center;
    background: url(../images/img6.png) no-repeat;
    background-size: 80px 175px;
    background-position: 0;
    padding: 52px 13px 66px;
    margin: 0px 35px;
    border: 0;
    line-height: normal;
}
.calgallery_box{
	position: relative;
	margin-bottom: 50px;
}
.calgallery_box > a{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.calgallery_imgbox{
    background-color: #ffffff;
    padding: 15px 15px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;
img{
	width: 100%;
}
}
.calgallery_txt h6{
	font-weight: 700;
	font-size: 22px;
	line-height: 52px;
	color: #444444;
	font-family: 'Kefa-Bold', sans-serif;
	text-transform: uppercase;
	text-align: center;
}
.calgallery_txt h6 a:hover{
    color: #655dc6;
}
.footer_list ul li:last-child a{
    width: 42px;
    height: 42px;
    background-color: #40cee0;
    border-radius: 50px;
	align-items: center;
	justify-content: center;
}
.footer_list ul li:last-child a i{
    color: #fff;
    font-size: 26px;
}
.footer_list ul li:last-child a:hover{
    background-color: #635ea9;
}
.logged-in .header{
	top: 32px;
}

/******Default WP Alignment Classes******/

.aligncenter,.alignleft,.alignright {
    display: block;
    padding: 0;
}

.aligncenter {
    float: none;
    margin: .5em auto 1em;
}

.alignright {
    float: right;
    margin: .5em 0 1em 1em;
}

.alignleft {
    float: left;
    margin: .5em 1em 1em 0;
}

.wp-caption {
    padding: 5px 0;
    border: 1px solid #555;
    background: #444;
    text-align: center;
}

.wp-caption img {
    display: inline;
}

.wp-caption p.wp-caption-text {
    margin: 5px 0 0;
    padding: 0;
    text-align: center;
    font-size: 75%;
    font-weight: 100;
    font-style: italic;
    color: #ddd;
}
.def_page{
	padding: 30px 0px;
}
.def_page h1{
    font-weight: 400;
    font-family: "Phosphate-Inline", sans-serif;
    font-size: 60px;
    color: #655dc6;
    text-transform: uppercase;
    letter-spacing: 5px;
	margin-bottom: 10px;
}
.def_page p{
	font-size: 19px;
	line-height: 28px;
	color: #444444;
	font-family: 'Kefa-Regular', sans-serif;
	margin-bottom: 10px;
}
.def_page ul, .def_page ol {
	padding-left: 16px;
}
.def_page ul li{
	font-size: 19px;
	line-height: 28px;
	color: #444444;
	font-family: 'Kefa-Regular', sans-serif;
}
.def_page ol li{
	font-size: 19px;
	line-height: 28px;
	color: #444444;
	font-family: 'Kefa-Regular', sans-serif;
}
.search-form input {
    border: 1px solid rgba(69, 69, 69, 0.4);
    width: 278px;
    color: rgba(69, 69, 69, 0.5);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin-right: 20px;
    padding: 3px 15px 4px;
    border-radius: 9px;
    background-color: #ffffff;
    font-family: "RobotoSlab-Regular", sans-serif;
}
.search-form input[type=submit] {
    background: #635ea9;
    border: 0;
    font-size: 18px;
    line-height: 28px;
    outline: 0;
    -webkit-appearance: none;
    border-radius: 50px;
    padding: 6px 12px;
    color: #ffffff;
    width: 152px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 700;
    font-family: "Kefa-Bold", sans-serif;
    margin-right: 0px;
}
.search-form input[type=submit]:hover {
    background: #00bed6;
    transition: 0.3s all ease-in-out;
}
.years_memo{
	margin-bottom: 55px;
}
.wrapper-nav {
    position:relative;
    margin:0 auto;
    overflow:hidden;
    padding:5px;
    height:240px;
}
.wrapper-nav .years_memo{
    padding-top: 135px;
}
.wrapper-nav .nav a.nav-item{
	border: 0;
	padding: 6px 32px;
	cursor: pointer;
}
.wrapper-nav .nav ul{
	padding: 0;
}
.list {
    position: absolute;
    left: 0px;
    top: 0px;
    min-width: 1130px;
    margin-top: 0px;
    right: 0;
    margin: 0 auto;
    justify-content: center;
	border: 0;
}
.list li{
    display:table-cell;
    position:relative;
    text-align:center;
    cursor:grab;
    cursor:-webkit-grab;
    color:#efefef;
    vertical-align:middle;
}
.scroller {
    text-align:center;
    cursor:pointer;
    display:none;
    padding:7px;
    padding-top:13px;
    white-space:nowrap;
    vertical-align:middle;
    background-color:#fff;
}
.tab-content { 
	padding: 0;
}
.btmtxt_sec .years_txt {
    max-width: 970px;
}
.cont_enquiry_sec {
    background-color: #fcd700;
    padding: 100px 0px;
	position: relative;
	overflow: hidden;
}
.cont_enquiry_sec:before {
    content: '';
	position: absolute;
	left: -110px;
    top: -73px;
	width: 847px;
	height: 481px;
	background: url('../images/hand-left.png') no-repeat;
	background-size: cover;
}
.cont_enquiry_sec:after {
    content: "";
    position: absolute;
    right: -110px;
    bottom: 72px;
    width: 848px;
    height: 476px;
    background: url(../images/hand-right.png) no-repeat;
    background-size: cover;
}
.cont_enquiry_main {
    max-width: 768px;
    margin: 0 auto;
    text-align: center;
	position: relative;
    z-index: 9;
}
.cont_enquiry_top {
    margin-bottom: 73px;
    position: relative;
}
.cont_enquiry_main h3 {
    font-weight: 400;
    font-family: "Phosphate-Inline", sans-serif;
    font-size: 60px;
    color: #655dc6;
    text-transform: uppercase;
    letter-spacing: 5px;
	margin-bottom: 10px;
}
.cont_enquiry_main p{
	margin-bottom: 40px;
}
.contact_main {
    display: flex;
    flex-wrap: wrap;
}
.contact_main input {
    border: 0 !important;
    border-radius: 0px !important;
    background-color: #fff !important;
    color: #444444 !important;
    padding: 5px 15px 2px !important;
    box-shadow: none !important;
    font-size: 19px !important;
    line-height: 28px !important;
    font-family: "Kefa-Regular", sans-serif !important;
    height: 33px;
}
.frm_style_formidable-style.with_frm_style .form-field {
    margin-bottom: 20px;
}
.cont_enquiry_main .form-group{
	margin-bottom: 20px;
}
.contact_main textarea {
    border: 0 !important;
    border-radius: 0px !important;
    background-color: #fff !important;
    color: #444444 !important;
    padding: 6px 15px !important;
    font-size: 19px !important;
	line-height: 28px !important;
	font-family: 'Kefa-Regular', sans-serif !important;
    height: 192px !important;
    box-shadow: none !important;
}
.contact_main .form-group p, .cont_enquiry_main .submit_btn p{
	margin-bottom: 0;
}
.contact-left {
    width: 47%;
    margin-right: 22px;
}
.contact-right {
    width: 50%;
}
.cont_enquiry_main .submit_btn{
	width: 100%;
}
.frm_submit button[type=submit], .submit_btn button[type=submit], .submit_btn input[type=submit] {
    display: block !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
    margin: 0 auto !important;
    margin-top: 40px !important;
	font-size: 18px !important;
    letter-spacing: 1px !important;
    line-height: 28px !important;
    text-transform: uppercase !important;
    color: #ffffff !important;
    font-weight: 700 !important;
    font-family: "Kefa-Bold", sans-serif !important;
    border-radius: 20px !important;
    background-color: #635fa9 !important;
    padding: 6px 37px !important;
	border: 0 !important;
	height: auto;
}
.frm_submit button[type=submit]:hover, .submit_btn button[type=submit]:hover, .submit_btn input[type=submit]:hover{
	background-color: #00bed6 !important;
    transition: 0.3s all ease-in-out;
}
.contact-right .form-group{
	height: 100%;
}
.cont_enquiry_main .form-group:last-child{
	margin-bottom: 0;
}
.contact_main input::placeholder, .contact_main textarea::placeholder {  
	color: #444444 !important;
}
.contact_main input::-webkit-input-placeholder, .contact_main textarea::-webkit-input-placeholder{  
	color: #444444 !important;
}
.contact_main input::-moz-placeholder, .contact_main textarea::-moz-placeholder {  
	color: #444444 !important;
}
.contact_main input:-ms-input-placeholder, .contact_main textarea:-ms-input-placeholder {  
	color: #444444 !important;
}
.contact_main input:-moz-placeholder, .contact_main textarea:-moz-placeholder{  
	color: #444444 !important;
}
.innpartner_sec .partner_sec{
	border: 0;
}
.newpartner_slider{
	max-width: 1118px;
	margin: 0 auto;
	ul{
		padding: 0;
		
		li{
			width: 23.659%;
			display: inline-block;
			margin-right: 20px;
			margin-bottom: 30px;
		}
	}
}
.newpartner_slider ul li:nth-child(4n+4){
	margin-right: 0;
}
.newpartner_slider ul li a {
    display: block;
    padding-bottom: 60.989%;
	position: relative;
}
.newpartner_slider ul li img{
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}
.staff_sec{
	background-color: #fcd700;
    padding: 90px 0px 0px;
	position: relative;
	    overflow: hidden;
}
.staff_sec:before {
    content: "";
    position: absolute;
    left: -110px;
    top: 92px;
    width: 847px;
    height: 481px;
    background: url(../images/hand-left.png) no-repeat;
    background-size: cover;
}
.staff_sec:after {
    content: "";
    position: absolute;
    right: -110px;
    bottom: 113px;
    width: 848px;
    height: 476px;
    background: url(../images/hand-right.png) no-repeat;
    background-size: cover;
}
.staff_main{
	max-width: 1082px;
	margin: 0 auto;
}
.staff_sec .stories_title {
    margin-bottom: 100px;
}
.staff_sec .stories_title h3{
    color: #655dc6;
}
.staff_box{
	text-align: center;
	position: relative;
	margin-bottom: 90px;
	z-index: 10;
}
.staff_img{
	margin-bottom: 23px;
}
.staff_img img{
	width: 100%;
}
.staff_txt h5{
	font-size: 48px;
	letter-spacing: 1px;
	line-height: 40px;
    margin-bottom: 5px;
	text-transform: uppercase;
	color: #444444;
	font-family: 'BebasNeue', sans-serif;
}
.staff_txt p{
	font-size: 18px;
	line-height: 30px;
	color: #655dc6;
	text-transform: uppercase;
	font-family: 'Kefa-Regular', sans-serif;
}

.staff_row .staff-col{
	padding-right: 35px;
	padding-left: 35px;
}
.staff_sec .stories_title h3:before {
    left: -110px;
    top: 25px;
    background: url(../images/green-hand1.png) no-repeat;
    background-size: cover;
}
.staff_sec .stories_title h3:after {
    right: -110px;
    top: 25px;
    background: url(../images/green-hand2.png) no-repeat;
    background-size: cover;
}
.makebtm .years_txt{
	margin-bottom: 45px;
}
.makevolunter_sec{
	padding-bottom: 95px;
}
.makevolunter_sec .container{
	max-width: 968px;
}
.makevolunter_img{
	max-width: 361px;
	margin: 0 auto;
	margin-bottom: 50px;
	min-height: 433px;
	
img{
	width: 100%;
}
}
.makevolunter_txt{
	text-align: center;
	position: relative;
	min-height: 310px;
}
.makevolunter_txt h5{
	font-size: 48px;
	letter-spacing: 1px;
	line-height: 40px;
    margin-bottom: 25px;
	text-transform: uppercase;
	color: #93d500;
	font-family: 'BebasNeue', sans-serif;
}
.makevolunter_txt p{
	margin-bottom: 18px;
}
.makevolunter_sec .make_col{
	padding: 0px 34px;
}
.makevolunter_txt a.btn{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	max-width: 186px;
    margin: 0 auto;
}
.becomepartner_sec{
    background-color: #00bed6;
    padding: 103px 0px;
	position: relative;
}
.becomepartner_sec:after {
    content: "";
    position: absolute;
    right: 275px;
    bottom: -20px;
    width: 848px;
    height: 476px;
    background: url(../images/hand-right.png) no-repeat;
    background-size: cover;
}
.becomepartner_main{
	max-width: 1050px;
	margin: 0 auto;
	position: relative;
    z-index: 9;
}
.becomepartner_img{
	max-width: 532px;
}
.becomepartner_img img{
	width: 100%;
}
.becomepartner_txt{
	padding-top: 115px;
	width: calc(100% - 532px);
	padding-left: 70px;
}
.becomepartner_txt h6{
	font-size: 34px;
    letter-spacing: 7px;
    line-height: 49px;
    text-transform: uppercase;
    color: #f9d506;
    font-family: "Kefa-Bold", sans-serif;
    font-weight: 700;
}
.becomepartner_txt h3{
	font-size: 98px;
    letter-spacing: 7px;
    line-height: 97px;
    font-family: "Phosphate-Inline", sans-serif;
    color: #655dc6;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.becomepartner_txt p{
    margin-bottom: 20px;
}
.becomepartner_txt a.btn{
	margin-top: 45px;
}
.becomepartner_txt a.btn:hover {
	background-color: #f9d506;
}
.newsletter_sec{
	padding-bottom: 120px;
}
.newsletter_sec .container{
	max-width: 917px;
}
.newsletter_title {
	margin-bottom: 50px;
}
.newsletter_title h3{
	font-size: 60px;
	letter-spacing: 8px;
	line-height: 80px;
	text-transform: uppercase;
	color: #655dc6;
	font-weight: 400;
	font-family: 'Phosphate-Inline', sans-serif;
	margin-bottom: 0px;
}
.newsletter_title h6{
	font-size: 34px;
    letter-spacing: 7px;
    line-height: 49px;
    text-transform: uppercase;
    color: #93d500;
    font-family: "Kefa-Bold", sans-serif;
    font-weight: 700;
}
.newsletter_box p{
    margin-bottom: 32px;
}
.newsletter_row .col-md-6{
	padding: 0px 19px;
}
.newsletter_box span {
	display: block;
    background-color: #ffffff;
    padding: 15px 15px;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.35);
    margin-bottom: 30px;
}
.newsletter_box span img {
    width: 100%;
}
.innevent_sec{
	background-color: #00bed6;
    padding: 80px 0px 90px;
	position: relative;
	overflow: hidden;
}
.innevent_sec:before {
    content: "";
    position: absolute;
    left: -110px;
    top: -73px;
    width: 847px;
    height: 481px;
    background: url(../images/hand-left.png) no-repeat;
    background-size: cover;
}
.innevent_sec:after {
    content: "";
    position: absolute;
    right: -110px;
    bottom: -72px;
    width: 848px;
    height: 476px;
    background: url(../images/hand-right.png) no-repeat;
    background-size: cover;
}
.innevent_sec .stories_title h3{
    color: #655dc6;
}
.innevent_sec .container{
	max-width: 1126px;
}
.newsbtm{
	margin-bottom: 0;
}
.event_slider {
	margin-bottom: 30px;
	z-index: 9;
}
.event_slider span{
	display: block;
    background-color: #ffffff;
    padding: 15px 15px;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.35);
    margin: 20px;
}
.event_slider span img {
    width: 100%;
}
.event_thanktxt{
	position: relative;
	z-index: 9;
}
.event_thanktxt h4{
	font-size: 60px;
	letter-spacing: 8px;
	line-height: 80px;
	text-transform: uppercase;
	color: #655dc6;
	font-weight: 400;
	font-family: 'Phosphate-Inline', sans-serif;
	margin-bottom: 0px;
}
.event_slider .slick-prev{ 
	left: -90px; 
	z-index: 9;  
	width: 59px; 
	height: 115px; 
}
.event_slider .slick-prev:before { 
	content: "";  
	position: absolute;  
	width: 59px; 
	height: 115px;  
	background-image: url('../images/w-arrow-left.png'); 
	top: 50%; 
	transform: translateY(-50%);
	z-index: 999; 
	left: 0; 
	background-size: 100%; 
	opacity: 1; 
	background-repeat: no-repeat; 
}
.event_slider .slick-next{ 
	right: -90px; 
	z-index: 9; 
	width: 59px; 
	height: 115px; 
}
.event_slider .slick-next:before { 
	content: "";  
	position: absolute; 
	width: 59px; 
	height: 115px; 
	background-image: url('../images/w-arrow-right.png'); 
	top: 50%; 
	transform: translateY(-50%);
	z-index: 999; 
	right: 0; 
	background-size: 100%; 
	opacity: 1; 
	background-repeat: no-repeat; 
}
.newnews_sec{
	padding: 110px 0px 150px;
}
.newnews_sec .container{
	max-width: 1098px;
}
.newnews_sec .stories_title h3{
    color: #655dc6;
}
.member_main{
	margin-bottom: 93px;
}
.member_box{
	background-color: #dfebbc;
	padding: 28px 50px 43px;
	width: 46.95%;
	text-align: center;
	margin-right: 65px;
}
.member_box:last-child{
	margin-right: 0px;
}
.member_img{
	position: relative;
	margin-bottom: 30px;
}
.member_img:before{
	content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 36%;
    height: 3px;
    background: #00bed6;
}
.member_img:after{
	content:'';
	position: absolute;
	right: 0;
	top: 50%;
    width: 36%;
	height: 3px;
	background: #93d500;
}
.member_img img{
	width: 90px;
    margin: 0 auto;
    background: #dfebbc;
    padding: 0 10px;
}
.member_txt h5{
	font-size: 48px;
	letter-spacing: 1px;
	line-height: 48px;
    margin-bottom: 60px;
	text-transform: uppercase;
	color: #444444;
	font-family: 'BebasNeue', sans-serif;
	min-height: 144px;
}
.video_sec {
    display: block;
    background-color: #ffffff;
    padding: 15px 15px;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.35);
}
.video_box {
    position: relative;
}
.video_box:before{
	content:'';
	position: absolute;
	left:0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(100, 96, 169, 0.5);
}
.video_box > img {
    width: 100%;
}
.video_box a.video_btn {
    position: absolute;
    width: 99px;
    height: 99px;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
}
.slick-slide {
	z-index: 998;
    position: relative;
}
.slick-active.slick-current {
	z-index: 999;
}

.calgallery_title_test a{
    display: flex;
    align-items: center;
    justify-content: center;
	margin-bottom: 65px;
}
.calgallery_title_test a span {
    font-size: 91px;
    letter-spacing: 1px;
    line-height: 100px;
    text-transform: uppercase;
    color: #444444;
    font-family: "BebasNeue", sans-serif;
    border-bottom: 5px solid #635fa9;
    text-align: center;
}
.calgallery_title_test a span:nth-child(2) {
    letter-spacing: 0;
    font-size: 43px;
    font-family: "Phosphate-Inline", sans-serif;
    color: #444444;
    font-style: normal;
    text-align: center;
    background: url(../images/img6.png) no-repeat;
    background-size: 80px 175px;
    background-position: 0;
    padding: 52px 13px 66px;
    margin: 0px 35px;
    border: 0;
    line-height: normal;
}
.grid-content h4{
	font-size: 32px;
	line-height: 40px;
	color: #444444;
	font-family: 'Kefa-Regular', sans-serif;
	font-weight: 400;
	margin-bottom: 15px;
}
.grid-content a.btn{
	font-size: 18px;
    letter-spacing: 1px;
    line-height: 28px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-family: "Kefa-Bold", sans-serif;
    border-radius: 20px;
    background-color: #635fa9;
    padding: 6px 35px;
	border: 0;
}
.grid-content a.btn i{
	margin-left: 10px;
}
.grid-content a.btn:hover{
	background-color: #00bed6;
}
.grid-sec{
	padding: 60px 0px;
}
nav.pagination{
	margin-top: 40px;
	padding: 0px 15px;
}
nav.pagination .nav-links a{
	color: #444444;
}
nav.pagination .nav-links span.current{
	color: #635fa9;
}
h2.entry-title, .search h2{
	margin-bottom: 15px;
}