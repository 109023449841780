@media (max-width: 1639px) {
.becomepartner_sec:after {  right: 75px; }
}
@media (max-width: 1439px) {
.container {  max-width: 95%; }
.becomepartner_sec:after {  right: 0; }
}
@media (max-width: 1365px) {
.header .header_main .logo { max-width: 510px; }
.make_difftxt { padding-right: 185px; }
main { margin-top: 131px; }
.banner_img > img {  height: calc(100vh - 131px); }
.inner_banner:before { background-position: bottom left; }
}
@media (max-width: 1279px) {
.header .header_main .logo { max-width: 450px; }
.header .header_main .navbar ul li { margin-right: 22px; }
.make_difftxt {  padding-right: 90px; }
main { margin-top: 124px; }
.banner_img > img {  height: calc(100vh - 124px); }
.slider-nav { max-width: 1060px; }
.newpartner_slider ul li { margin-right: 19px; }
.stories_title {  margin-bottom: 50px; }

}
@media (max-width: 1179px) {
.header { padding: 25px 0px; }
.header .header_main .logo { max-width: 370px; }
.header .header_main .navbar ul li {  margin-right: 20px; }
.make_difftxt { padding-right: 35px; }
.banner_title h3, .make_difftxt h3, .stories_title h3, .partner_title h3, .news_title h3, .inner_bannertxt h1, .inndiffrence_txt h3, .becomepartner_txt h3 { font-size: 90px; line-height: 90px;}
.news_box { margin-right: 84px; }
.banner_img > img { height: calc(100vh - 90px); }
main { margin-top: 90px; }
.slider-nav { max-width: 980px; }
.inndiffrence_txt {  width: calc(100% - 505px); }
.newpartner_slider ul li { margin-right: 17px; }
.becomepartner_img { max-width: 470px;}
.becomepartner_txt { width: calc(100% - 470px);}
.event_slider { max-width: 85%;  margin: 0 auto;  margin-bottom: 30px; }
.event_slider .slick-next { right: -80px; }
.event_slider .slick-prev { left: -80px; }
.member_box { margin-right: 59px;}
.newnews_sec .container { max-width: 95%; }
}
@media (max-width: 1079px) {
.header .header_main .logo { max-width: 330px;}
.header .header_main .navbar ul li {  margin-right: 13px; }
.banner_title h3, .make_difftxt h3, .stories_title h3, .partner_title h3, .news_title h3, .inner_bannertxt h1, .inndiffrence_txt h3, .becomepartner_txt h3 { font-size: 80px; line-height: 80px;}
.inner_bannerimg img {  height: 575px; }
.inndiffrence_txt { width: calc(100% - 470px); }
.calgallery_title span { font-size: 80px; line-height: 90px; }
.slider-nav { max-width: 895px; }
.staff_txt h5, .makevolunter_txt h5 {font-size: 40px;}
.newpartner_slider ul li { margin-right: 16px; }
.staff_sec:before {  width: 690px;  height: 390px; }
.staff_sec:after {  width: 690px;  height: 390px; bottom: 35px; }
.inndiffrence_sec:before {  bottom: -40px;  width: 660px;  height: 454px; }
.becomepartner_txt { padding-left: 50px; }
.member_box {
    margin-right: 54px;
}
}
@media (max-width: 991px) {
body.open { overflow: hidden; }
body.open #wrapper:before{ opacity: 1; visibility: visible; z-index: 9; }
.nav-icon { display: block; z-index: 99; } 
.header .header_main .navbar { display: none; position: fixed;  top: 88px; left: 0; width: 100%; right: 0; float: none; z-index: 99; background: #ffffff;
 transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out;
 -o-transition: all 0.3s ease-in-out; padding: 20px 35px; overflow: auto; max-height: calc(100vh - 88px); max-height: -moz-calc(100vh - 88px);  }
.open .header .header_main .navbar { display: block;  }
.header .header_main .main_menu nav ul { display: block; }
.header .header_main .navbar ul li { display: block; margin: 0; margin-bottom: 15px; }
.header .header_main .navbar ul li:last-child { margin-bottom: 0px; }
.banner_img { clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);}
.stories_box {  margin-right: 66px; }
.stories_txt span , .stories_txt h4, .member_txt h5 { font-size: 44px; }
.stories_sec:before {  width: 670px; height: 400px; }
.banner_title h3, .make_difftxt h3, .stories_title h3, .partner_title h3, .news_title h3, .inner_bannertxt h1, .inndiffrence_txt h3, .becomepartner_txt h3 { font-size: 55px;  line-height: 55px; }
.make_difftxt { width: calc(100% - 300px); }
.make_diffimg { max-width: 300px;  margin: 0 auto; }
.footer_form { width: 100%;    margin-bottom: 20px; }
.footer_form h3 { font-size: 30px; }
.emailbox {  max-width: 400px; }
.news_box { padding: 38px 25px; margin-right: 59px;}
.news_txt { padding-left: 10px; padding-right: 50px; }
.news_txt span {  right: 20px; }
.news_txt h4, .grid-content h4  { font-size: 30px; line-height: 38px;}
.footer .footer_top { margin-bottom: 20px; }
.news_title h3:before { left: -100px; top: 7px; }
.news_title h3:after { right: -100px; top: 7px; }
.testimonial_sec { padding: 60px 0px;}
.testimonial_slider p { font-size: 32px; line-height: 42px; }
.make_difftxt ul { padding: 0;}
.make_difftxt ul li {  margin-right: 20px; }
.make_difftxt ul li a { font-size: 18px; }
.make_difftxt ul li img { max-width: 85px; }
.stories_btm p br{ display: none; }
.banner_img > img { height: calc(100vh - 88px); }
main { margin-top: 88px; }
.click_menu { display: block;}
.header .header_main .main_menu nav ul li.open2 ul.sub-menu{ display: block; opacity: 1; visibility: visible; position: relative; width: 100%; padding: 20px 15px 10px;
background-color: transparent;}
.header .header_main .main_menu nav ul.sub-menu li { padding-bottom: 0px; }
.header .header_main .main_menu nav ul.sub-menu li a {  color: #444444; font-size: 14px;}
.open2 > .click_menu {transform: rotate(-135deg);top: 9px;}
.calgallery_title span { font-size: 70px; line-height: 80px; }
.calgallery_box { margin-bottom: 30px;}
.inndiffrence_img {  max-width: 330px;}
.inndiffrence_txt { width: calc(100% - 330px);}
.inner_bannerimg img { height: 500px;}
.inndiffrence_img img { height: 385px;}
.bryson_txt { width: calc(100% - 316px); }
.slider-nav { max-width: 660px;}
.contact-left { margin-right: 20px;}
.newpartner_slider ul li { margin-right: 12px;}
.stories_title h3:before { top: 8px; }
.stories_title h3:after { top: 8px; }
.makevolunter_img {  margin-bottom: 30px; min-height: 370px; }
.makevolunter_txt { min-height: 340px; }
.becomepartner_sec {  padding: 83px 0px; }
.becomepartner_txt {  padding-left: 30px; padding-top: 60px;  width: calc(100% - 340px); }
.becomepartner_sec:after {  width: 650px; height: 365px; }
.cont_enquiry_sec:before, .innevent_sec:before { top: -30px;   width: 750px;  height: 416px; }
.cont_enquiry_sec:after, .innevent_sec:after {  width: 750px;  height: 416px; }
.member_box { margin-right: 41px;}
.member_box {  background-color: #dfebbc; padding: 28px 35px 43px;}
.event_slider .slick-prev { left: -55px; width: 45px; height: 90px; }
.event_slider .slick-prev:before { width: 45px; height: 90px; }
.event_slider .slick-next { right: -55px; width: 45px; height: 90px; }
.event_slider .slick-next:before { width: 45px; height: 90px; }
}
@media screen and (min-width: 768px) and (max-height: 970px) {
.banner_txt {  padding: 60px 0px 90px; }
.banner_img > img {  height: calc(100vh - 65px); }
}
@media screen and (min-width: 768px) and (max-height: 880px) {
.banner_img > img { height: 820px; }
}
@media (max-width: 767px) {
.stories_box { width: 100%; max-width: 400px;  margin: 0 auto;  margin-bottom: 40px; }
.stories_box:last-child , .news_box:nth-child(2){ margin: 0 auto; }
.banner_maintxt { max-width: 400px; }
.our_mission_title h2 { font-size: 40px; }
.stories_title h3:before { top: 11px; width: 70px; height: 34px; }
.stories_title h3:after { top: 11px; width: 70px; height: 34px; }
.stories_sec:before {  width: 610px;  height: 350px; bottom: -80px;}
.make_diffsec { flex-direction: column-reverse; padding-bottom: 50px; }
.make_difftxt { width: 100%;  padding-right: 0; }
.make_difftxt h6 { padding-top: 50px; }
.make_difftxt ul { justify-content: center; }
.partner_sec { padding: 50px 0px; }
.partner_slider.slick-initialized .slick-slide {  margin: 0px 10px;}
.partner_title h3 { margin-bottom: 30px; }
.testimonial_slider p { font-size: 26px;  line-height: 38px; }
.news_box { width: 100%; max-width: 400px; margin: 0 auto; margin-bottom: 40px; }
.news_btn { margin-top: 50px; }
.banner_title h3, .make_difftxt h3, .stories_title h3, .partner_title h3, .news_title h3, .inner_bannertxt h1, .inndiffrence_txt h3, .becomepartner_txt h3 { font-size: 50px;     letter-spacing: 6px;}
.news_title h3 { letter-spacing: 6px; }
.footer {  padding: 28px 0px;}
.footer_form { display: block; }
.footer_form h3 { margin: 0 0 15px;}
.footer_list ul li a i {  font-size: 32px; }
.banner_title h6 {  font-size: 24px; letter-spacing: 3px; }
.make_difftxt h6, .becomepartner_txt h6, .inndiffrence_txt h6 , .newsletter_title h6 { font-size: 30px; letter-spacing: 5px; line-height: 40px; }
.news_title h3:before { left: -72px; top: 11px; width: 60px; height: 29px; }
.news_title h3:after { right: -72px; top: 11px; width: 60px; height: 29px; }
.years_txt p br{ display: none; }
.years_slider { max-width: 95%; }
.slider-nav { max-width: 85%; }
.calgallery_title span { font-size: 54px; line-height: 64px;}
.calgallery_title em { font-size: 38px; background-size: 74px 140px;  padding: 42px 13px; }
.inndiffrence_img { max-width: 450px; margin: 0 auto;  position: relative !important; }
.inndiffrence_sec .container {  flex-direction: column-reverse;}
.inndiffrence_txt { width: 100%; }
.inndiffrence_sec:before { width: 600px; height: 345px;  background: url(../images/hand-img2.png) no-repeat; background-size: cover;}
.bryson_txt h3, .newsletter_title h3 ,.event_thanktxt h4{ font-size: 46px; letter-spacing: 6px; line-height: 56px; }
.cont_enquiry_main h3 { font-size: 46px;  line-height: 56px; }
.bryson_img { margin: 0 auto; margin-bottom: 30px; }
.bryson_txt { width: 100%;  padding: 0; text-align: center; }
.bryson_sec { padding-bottom: 50px; }
.gallery_sec { padding: 70px 0px 50px; }
.inndiffrence_txt { padding: 45px 0px; }
.inntestimonial_sec { padding: 40px 0px; }
.calgallery_sec {  padding-bottom: 40px; }
.footer_list ul li:last-child a { width: 32px; height: 32px; }
.footer_list ul li:last-child a i {  font-size: 18px; }
.years_txt {  margin-bottom: 30px; }
.contact_main { flex-wrap: wrap; }
.cont_enquiry_sec { padding: 50px 0px; }
.cont_enquiry_top { margin-bottom: 50px; }
.contact-left {  width: 100%; margin-right: 0;}
.contact-right {  width: 100%; }
.cont_enquiry_main .form-group:last-child {  margin-bottom: 20px; }
.contact_main textarea { height: 140px !important; }
.frm_submit button[type=submit], .submit_btn button[type=submit], .submit_btn input[type=submit] {     margin-top: 20px !important; }
.years_txt p { font-size: 18px; margin-bottom: 20px; }
.newpartner_slider ul li {  width: 30.659%; margin-right: 12px; }
.newpartner_slider ul li:nth-child(4n+4) {  margin-right: 12px;}
.newpartner_slider ul li:nth-child(3n+3) { margin-right: 0px; }
.partner_title em:before { top: 20px; }
.partner_title em:after { top: 20px; }
.makevolunter_box{ margin-bottom: 30px; }
.makevolunter_txt {  min-height: auto; }
.makevolunter_txt a.btn {  position: relative; }
.becomepartner_img {  margin: 0 auto; }
.becomepartner_txt { padding-top: 30px; padding-left: 0; width: 100%;    text-align: center;}
.becomepartner_txt a.btn {  margin-top: 20px;}
.makevolunter_sec { padding-bottom: 40px;}
.becomepartner_sec {  padding: 60px 0px;}
.makevolunter_img { min-height: auto;}
.newsletter_sec { padding-top: 20px; padding-bottom: 40px; }
.member_box {  width: 100%; margin-right: 0;  margin-bottom: 30px; }
.member_txt h5 { min-height: auto; }
.newnews_sec {  padding: 70px 0px;}
.member_main { margin-bottom: 30px;}
.cont_enquiry_sec:before, .innevent_sec:before {  width: 630px;  height: 350px; }
.cont_enquiry_sec:after, .innevent_sec:after { width: 630px; height: 350px; }
.grid-box{ margin-bottom: 30px;}
}
@media screen and (max-width: 767px) and (max-height: 850px) {
.banner_txt { padding: 40px 0px 90px; }
}
@media screen and (max-width: 767px) and (max-height: 750px) {
.banner_title h5 {  margin-bottom: 0; }
}
@media (max-width: 639px) {
.partner_title em:before { width: 35%; }
.partner_title em:after { width: 35%; }
.event_slider .slick-prev { left: -30px; width: 30px; height: 60px; }
.event_slider .slick-prev:before { width: 30px; height: 60px; }
.event_slider .slick-next { right: -30px; width: 30px; height: 60px; }
.event_slider .slick-next:before { width: 30px; height: 60px; }
}
@media (max-width: 575px) {
.stories_title { margin-bottom: 50px; }
.stories_title h3 { letter-spacing: 7px; }
.stories_title h3:before { top: 11px; width: 60px;  height: 29px; left: -80px; }
.stories_title h3:after { top: 13px; width: 60px;  height: 29px; right: -70px; }
.news_title { margin-bottom: 50px; }
.news_sec { padding: 70px 0px; }
.news_title h3 { letter-spacing: 4px; font-size: 38px; line-height: 48px; }
.news_title h3:before { top: 8px; }
.news_title h3:after { top: 8px; }
.news_sec:before { width: 600px; height: 400px;}
.partner_slider { margin-bottom: 40px; }
.partner_title h6 { font-size: 22px; line-height: 38px;  letter-spacing: 3px;}
.partner_title em {  font-size: 26px; letter-spacing: 4px; }
.partner_title em:before { width: 30%; top: 21px; }
.partner_title em:after { width: 32%; top: 21px; }
.partner_title p, .make_difftxt p, .our_mission_txt p, .banner_box p, .partner_title p, .makevolunter_txt p, .becomepartner_txt p, .newsletter_box p{  font-size: 18px; }
.banner_title h5 { font-size: 32px; line-height: 48px;}	
.banner_title h5:before {  top: 13px; }
.banner_title h5:after {  top: 13px; }
.stories_main { margin-bottom: 50px; }
.inndiffrence_img { max-width: 420px;}
.years_slider , .slider-nav { padding: 0px 15px;}
.newpartner_slider ul li { width: 31.4%; }
.staff-col { flex: 0 0 auto; width: 50%; }
.staff_row .staff-col { padding-right: 20px; padding-left: 20px;}
.staff_txt h5, .makevolunter_txt h5 { font-size: 32px; line-height: 34px; }
.staff_box {  margin-bottom: 45px; }
.makevolunter_sec .make_col { padding: 0px 20px;}
.btmtxt_sec,.newsbtm { padding: 20px 0 0; }
.video_box a.video_btn {  width: 65px; height: 65px; }
.innevent_sec {  padding: 50px 0px; }
.stories_title { margin-bottom: 25px;}
.cont_enquiry_sec:before, .innevent_sec:before {  width: 540px; height: 307px; }
.cont_enquiry_sec:after, .innevent_sec:after {  width: 540px; height: 307px; }
}
@media (max-width: 479px) {
.container { max-width: 98%; }
.header { padding: 20px 0px; }
.header .header_main .logo {  max-width: 250px; }
.nav-icon { right: 19px; top: 24px; }
main {  margin-top: 69px; }
.banner_img > img {  height: calc(100vh - 69px); }
.header .header_main .navbar { top: 69px;  max-height: calc(100vh - 69px); max-height: -moz-calc(100vh - 69px);    padding: 20px 20px; }
.banner_box {  padding: 40px 20px 51px; background-size: cover; }
.make_difftxt ul li { width: 100%; margin-right: 0; display: block; margin-bottom: 20px; }
.testimonial_slider span {  font-size: 18px; }
.news_title h3 { letter-spacing: 3px;  font-size: 32px; line-height: 36px;}
.news_title h3:before { top: 9px; left: -48px; width: 40px; height: 20px;}
.news_title h3:after { top: 9px; right: -48px; width: 40px; height: 20px;}
.news_sec:before { width: 530px; height: 340px; }
.news_txt h4, .grid-content h4 { font-size: 26px; line-height: 32px;}
.emailbox input { width: 215px;  margin-right: 10px; }
.emailbox input[type=submit] { font-size: 16px; }
.stories_txt span, .stories_txt h4 , .member_txt h5{ font-size: 36px; line-height: 42px; }
.news_btn a.btn.btn-purple { padding: 6px 20px;}
.make_diffsec {  padding-bottom: 30px; }
.stories_title h3:before { top: 14px; width: 50px; height: 24px; left: -68px;}
.stories_title h3:after { top: 16px; width: 50px; height: 24px; right: -58px;}
.inndiffrence_img { max-width: 280px; }
.inndiffrence_img img { height: 310px;}
.years_slider .slick-prev, .slider-nav .slick-prev{ left: -20px;}
.years_slider .slick-next, .slider-nav .slick-next  {  right: -20px;}
.calgallery_title span { font-size: 40px; line-height: 54px;}
.calgallery_title { margin-bottom: 40px;}
.calgallery_title em { font-size: 32px; background-size: 64px 130px;padding: 40px 13px;margin: 0px 20px;}
.inner_bannerimg img { height: 420px;}
.year_txt h5 { font-size: 22px;}
.inner_bannerimg { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);}
.inner_banner:before {  bottom: 25px;  height: 260px; }
.cont_enquiry_main h3 {  font-size: 38px;  line-height: 42px; }
.staff-col { flex: auto; width: 100%;}
.staff_box { width: 248px;  margin: 0 auto; margin-bottom: 30px;}
.newpartner_slider ul li {  width: 47.7%;}
.newpartner_slider ul li:nth-child(3n+3) {  margin-right: 12px;}
.newpartner_slider ul li:nth-child(4n+4) {  margin-right: 12px;}
.newpartner_slider ul li:nth-child(2n+2) { margin-right: 0px; }
.becomepartner_txt h3 { margin-bottom: 15px; }
.newsletter_title { margin-bottom: 30px; }
.bryson_txt h3, .newsletter_title h3, .event_thanktxt h4 {  font-size: 36px; letter-spacing: 4px;  line-height:48px;}
.event_slider { max-width: 90%; }
.event_slider span { display: block; background-color: #ffffff;  padding: 10px 10px;  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.35); margin: 12px; }
.event_slider .slick-prev { left: -25px; width: 20px; height: 40px; }
.event_slider .slick-prev:before { width: 20px; height: 40px; }
.event_slider .slick-next { right: -25px; width: 20px; height: 40px; }
.event_slider .slick-next:before { width: 20px; height: 40px; }
.newnews_sec { padding: 50px 0px;}
.member_box {  padding: 25px 20px; }
.member_img:before, .member_img:after {  width: 30%; }
.member_txt h5 {  margin-bottom: 35px; }
.cont_enquiry_sec:before, .innevent_sec:before {  width: 386px;  height: 210px; }
.cont_enquiry_sec:after, .innevent_sec:after { width: 386px; height: 210px; }
.innpartner_sec .partner_sec { padding-bottom: 20px; }
}
@media (max-width: 375px) {
.header .header_main .logo { max-width: 215px; }
.nav-icon { top: 23px; width: 30px; height: 22px; }
.nav-icon span:nth-child(1) { width: 30px; }
.nav-icon span:nth-child(2) { top: 9px;  width: 30px; }
.nav-icon span:nth-child(3) {  top: 18px; width: 30px; }
.header .header_main .navbar { top: 65px;  max-height: calc(100vh - 65px); max-height: -moz-calc(100vh - 65px); }
.banner_title h6 {  font-size: 22px; }
.banner_title em {  font-size: 24px; letter-spacing: 3px; }
.banner_title em:before { top: 19px; width: 35%; }
.banner_title em:after { top: 19px; width: 35%; }
main { margin-top: 65px;}
.banner_img > img {  height: calc(100vh - 49px); }
.stories_txt span, .stories_txt h4, .member_txt h5 {  font-size: 30px; line-height: 38px; }
.stories_title h3 { letter-spacing: 4px; font-size: 38px; line-height: 38px;}
.stories_title h3:before { top: 7px; width: 50px; height: 24px; left: -64px;}
.stories_title h3:after { top: 7px; width: 50px; height: 24px; right: -64px;}
.make_difftxt h3{ font-size: 42px;  letter-spacing: 4px;}
.partner_title em:before { width: 25%;}
.partner_title em:after { width: 26%;}
.make_difftxt h6 , .becomepartner_txt h6, .inndiffrence_txt h6, .newsletter_title h6{  font-size: 24px; letter-spacing: 4px; line-height: 32px;}
.testimonial_slider p {  font-size: 22px;  line-height: 34px;}
.testimonial_slider em { font-size: 70px; line-height: 70px; }
.news_txt span { width: 42px;}
.news_txt {  padding-left: 0;  padding-right: 40px;}
.news_txt h4, .grid-content h4  {font-size: 22px; line-height: 28px;}
.btn.btn-purple { font-size: 16px;}
.news_btn a.btn.btn-purple { padding: 6px 13px;}
.news_sec:before {  width: 450px; height: 290px;}
.news_title h3:before, .news_title h3:after, .staff_sec .stories_title h3:before , .staff_sec .stories_title h3:after { display: none; }
.emailbox input { width: 160px;  margin-right: 0; border-radius: 0; padding: 5px 15px; }
.emailbox input[type=submit] { font-size: 16px;  border-radius: 0; width: 130px;}
.stories_txt { padding: 0px 12px; }
.inndiffrence_img { max-width: 260px;}
.inndiffrence_txt h3 { font-size: 44px;letter-spacing: 5px; }
.banner_title h3 { font-size: 46px;}
.makediff_banner .inner_bannertxt h1 { font-size: 45px; letter-spacing: 4px; }
.video_box a.video_btn { width: 50px;  height: 50px;}
}
@media screen and (max-width: 767px) and (max-height: 850px) {
.banner_img > img { height: 795px;}
.banner_title h5 { margin-bottom: 20px; }
.banner_box {  padding: 30px 20px 45px;}
}
@media screen and (max-width: 767px) and (max-height: 800px) {
.banner_img > img { height: 705px;}
}
@media screen and (min-width: 992px){
.header .header_main .main_menu nav ul li:hover>.sub-menu {  opacity: 1; visibility: visible; display: block !important; }
}